import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/organisms/hero"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Hero
      heading="404 - page not found"
      lede="You just hit a route that doesn't exist... the sadness."
      style={{ zIndex: "1" }}
      event={null}
    />
    <div
      className="index"
      style={{
        zIndex: "2",
        position: "relative",
        backgroundColor: "#ffffff",
        minHeight: "800px",
      }}
    >
      <div className="grid">
        <div className="grid-child grid--push-start-3 grid-child--8-col-l grid-child--6-col-s">
          <div className="wrapper">
            <div className="privacy-policy body">
              <p>
                There isn't much going on here, why not check out our event
                instead?
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
